import React from 'react'
import Layout from '../components/Layout'
import PageFooter from '../components/PageFooter'
import SideBar from '../components/Sidebar'
import config from '../../config'
import Icon from '../components/Icon'
import ContactForm from '../components/ContactForm'

const IndexPage = () => (
  <Layout>
    <SideBar sections={config.sections} />

    <div id='main'>
      <section id='top' className='one'>
        <div className='container'>
          <div data-aos='fade-left'>
            <h1>{config.firstName} <span className='text-primary'>{config.lastName}</span></h1>
            <div className='subheading mb-1'>
              {config.jobTitle}
            </div>
          </div>
          <p className='lead mb-3' data-aos='fade-zoom-in'>
            Has years of experience developing numerous commercially successful web applications with different teams, playing mission critical roles in the development lifecycle process. Highly skilled in both front-end and back-end areas of web development using a wide range of technologies across multiple frameworks, operating systems and scripting languages.
            <br /><br />Has server management and deployment experience from development up to production environments utilizing different strategies and tools for implementing continuous integration and delivery.
          </p>
          <div className='social-icons'>
            {config.socialLinks.map((social, index) => {
              const { icon, category, name, url } = social
              const delay = index * 100
              return (
                <a data-aos='fade-down' data-aos-delay={delay} target='_blank' rel='noreferrer noopener' key={url} href={url} title={name}>
                  <Icon faCategory={category} faIcon={icon} name={name} />
                </a>
              )
            })}
          </div>
        </div>
      </section>

      <section id='skills' className='two'>
        <div className='container'>
          <header><h2 className='mb-1' data-aos='fade-zoom-in'>Skills and Tools</h2></header>

          <div className='subheading' data-aos='fade-right'>
            <h3>Web Development Stack</h3>
          </div>
          <ul className='list-inline dev-icons mb-half'>
            {config.webDevelopment.map((webDevelopment, index) => {
              const { icon, category, name } = webDevelopment
              const delay = 100 + (index * 50)
              return (
                <li data-aos='fade-down' data-aos-delay={delay} className='list-inline-item' key={index} title={name}>
                  <Icon faCategory={category} faIcon={icon} name={name} />
                </li>
              )
            })}
          </ul>

          <div className='subheading' data-aos='fade-right' data-aos-delay='1100'>
            <h3>DevOps Stack</h3>
          </div>
          <ul className='list-inline dev-icons mb-half'>
            {config.devOps.map((devOps, index) => {
              const { icon, category, name } = devOps
              const delay = 1200 + (index * 50)
              return (
                <li data-aos='fade-down' data-aos-delay={delay} className='list-inline-item' key={name} title={name}>
                  <Icon faCategory={category} faIcon={icon} name={name} />
                </li>
              )
            })}
          </ul>

          <div className='subheading' data-aos='fade-right' data-aos-delay='1900'>
            <h3>3rd Party API Integrations</h3>
          </div>
          <ul className='list-inline dev-icons mb-half'>
            {config.api.map((api, index) => {
              const { icon, category, name } = api
              const delay = 2000 + (index * 50)
              return (
                <li data-aos='fade-down' data-aos-delay={delay} className='list-inline-item' key={name} title={name}>
                  <Icon faCategory={category} faIcon={icon} name={name} />
                </li>
              )
            })}
          </ul>

          <div className='subheading' data-aos='fade-right' data-aos-delay='2350'>
            <h3>Other Tools</h3>
          </div>
          <ul className='list-inline dev-icons mb-half'>
            {config.others.map((others, index) => {
              const { icon, category, name } = others
              const delay = 2450 + (index * 50)
              return (
                <li data-aos='fade-down' data-aos-id='others' data-aos-delay={delay} className='list-inline-item' key={name} title={name}>
                  <Icon faCategory={category} faIcon={icon} name={name} />
                </li>
              )
            })}
          </ul>
        </div>
      </section>

      <section id='experience' className='three'>
        <div className='container'>
          <header>
            <h2 className='mb-1' data-aos='fade-zoom-in'>Experience</h2>
          </header>
          <div className='row'>
            <div className='col-6 col-12-mobile'>
              <div className='mb-2' data-aos='fade-right' data-aos-delay='300'>
                <h3>Work</h3>
              </div>

              <div className='experience mb-2' data-aos='fade-down' data-aos-delay='600'>
                <div className='jobtitle'>Full Stack Web Developer / DevOps Engineer</div>
                <div className='company'>Business Switch Pty Ltd</div>
                {/* <div className='description'>Description</div> */}
                <div className='date'>August 2013 - May 2019</div>
              </div>

              <div className='experience mb-2' data-aos='fade-down' data-aos-delay='900'>
                <div className='jobtitle'>PHP Developer</div>
                <div className='company'>Life Data LLC.</div>
                {/* <div className='description'>Description</div> */}
                <div className='date'>August 2012 - August 2013</div>
              </div>

              <div className='experience mb-2' data-aos='fade-down' data-aos-delay='1200'>
                <div className='jobtitle'>Junior Web Developer</div>
                <div className='company'>Domains DotPH Inc.</div>
                {/* <div className='description'>Description</div> */}
                <div className='date'>September 2006 - 2009</div>
              </div>

            </div>
            <div className='col-6 col-12-mobile test'>
              <div className='mb-2' data-aos='fade-right' data-aos-delay='1500'>
                <h3>Education</h3>
              </div>

              <div className='experience' data-aos='fade-down' data-aos-delay='1800'>
                <div className='course'>Bachelor of Science - Computer Science</div>
                <div className='school'>AMA Computer College - Caloocan City, Philippines</div>
                {/* <div className='description'>Description</div> */}
                <div className='date'>2003 - June 2006</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='contact' className='four'>
        <div className='container'>
          <header>
            <h2 className='mb-1' data-aos='fade-zoom-in'>Contact</h2>
          </header>
          <ContactForm />
        </div>
      </section>
    </div>

    <PageFooter />
  </Layout>
)

export default IndexPage
