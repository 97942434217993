import React, { Component } from 'react'
import axios from 'axios'
import { Textbox, Textarea } from 'react-inputs-validation';
import Icon from '../components/Icon'
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../config'

class ContactForm extends Component {
  constructor(props){
    super(props)
    this.recaptchaRef = React.createRef();
    this.state = {
      name: "",
      email: "",
      message: "",
      hasNameError: false,
      hasEmailError: false,
      hasMessageError: false,
      validate: false,
      notifyMessage: '',
      sending: false
    };
    this.validateForm = this.validateForm.bind(this);
  }

  toggleValidating = (validate) => {
     this.setState({ validate })
  }

  validateForm = (e) => {
    e.preventDefault();
    this.toggleValidating(true);

    const {
      hasNameError,
      hasEmailError,
      hasMessageError,
    } = this.state;

    if (
      !hasNameError&&
      !hasEmailError&&
      !hasMessageError
    ) {
      this.recaptchaRef.current.execute()
    }
  }

  formSubmit = () => {
    this.setState({
      notifyMessage: '...sending',
      sending: true
    })

    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    }

    let headers = {
      headers: {
        'X-Api-Key': config.apiKey,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    }
    axios.post(config.apiUrl, data, headers)
    .then( res => {
      console.log(res)
      this.setState({ sent: true }, this.resetForm())
    })
    .catch( () => {
      this.setState({
          name: '',
          message: '',
          email: '',
          notifyMessage: 'Message sending failed!',
          sending: false
      })
      this.recaptchaRef.current.reset()
    })
  }

  resetForm = () => {
    this.setState({
        name: '',
        message: '',
        email: '',
        notifyMessage: 'Message Sent!',
        sending: false
    })
    this.recaptchaRef.current.reset()
  }

  render () {
    const {
      name,
      email,
      message,
      validate
    } = this.state
    let button
    if(this.state.sending) button = <Icon faCategory='fas' faIcon='spinner' spin={this.state.sending} />
    else button = 'Send Message'

    return (
      <form method='post' onSubmit={this.validateForm}>
        <div className='row'>
          <div className='col-6 col-12-mobile' data-aos='fade-right' data-aos-delay='200'>
            <Textbox
              attributesInput={{
                name: 'name',
                type: 'text',
                placeholder: 'Enter your name.',
              }}
              onChange={(name, e) => {
                this.setState({ name })
              }}
              value={name}
              validate={validate}
              validationCallback={res =>
                this.setState({ hasNameError: res, validate: false })
              }
              onBlur={(e) => {console.log(e)}}
              validationOption={{
                name: 'Name',
                check: true,
                required: true,
                type: 'string'
              }}
            />
          </div>
          <div className='col-6 col-12-mobile' data-aos='fade-left' data-aos-delay='400'>
            <Textbox
              attributesInput={{
                name: 'email',
                type: 'text',
                placeholder: 'Enter your email address.',
              }}
              onChange={(email, e) => {
                this.setState({ email })
              }}
              value={email}
              validate={validate}
              validationCallback={res =>
                this.setState({ hasNameError: res, validate: false })
              }
              onBlur={(e) => {console.log(e)}}
              validationOption={{
                name: 'Email Address',
                check: true,
                required: true,
                type: 'string',
                customFunc: email => {
                  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (reg.test(String(email).toLowerCase())) {
                    return true;
                  } else {
                    return "is not a valid email address";
                  }
                }
              }}
            />
          </div>
          <div className='col-12' data-aos='fade-down' data-aos-delay='600'>
          <Textarea
            attributesInput={{
              name: 'message',
              type: 'text',
              placeholder: 'Enter your message.',
              rows: 10
            }}
            onChange={(message, e) => {
              this.setState({ message })
            }}
            value={message}
            validate={validate}
            validationCallback={res =>
              this.setState({ hasNameError: res, validate: false })
            }
            onBlur={(e) => {console.log(e)}}
            validationOption={{
              name: 'Message',
              check: true,
              required: true,
              type: 'string',
            }}
          />
          </div>
          <div className='col-12' data-aos='fade-up' data-aos-delay='800'>
            <button type='submit' disabled={this.state.sending}>{button}</button>
            <span className="notify-status">{this.state.notifyMessage}</span>
          </div>

          <ReCAPTCHA
            sitekey={config.recaptchaKey}
            onChange={() => {this.formSubmit()}}
            ref={this.recaptchaRef}
            size="invisible"
          />
        </div>
      </form>
    )
  }
}

export default ContactForm
